<template>
  <div class="container" ref="container">
    <div class="topimg">
      <div class="usermsg">
        <div class="">账号/ID: {{ userId }}</div>
        <div class="">报告生成时间:{{ addTime }}</div>
      </div>
    </div>
    <div class="content">
      <!-- 报告说明 -->
      <section class="report-section">
        <div class="section-title">一、报告说明</div>
        <p class="section-description">非常感谢您完成员工心理健康测评问卷。</p>
        <ol class="section-list">
          <li>
            1.心理测评是了解心理状态的方法之一，其准确性受是否理解题意与认真作答、作答动机等多种因素的影响。在阅读本测评报告时，请结合作答时的状态，并参照日常生活中的实际情况来理解测试结果。
          </li>
          <li>
            2.人的心理状态是动态变化的过程，不要因为1次测评给自己贴上负面标签，可以用理性、积极的眼光看待本次测评。
          </li>
          <li>
            3.测评报告供您初步了解自己当前的心理状态，不能作为临床诊断的依据，如果您对测评结果有疑问或正在受心理问题困扰，请向专业人员寻求建议和帮助。
          </li>
        </ol>
      </section>

      <!-- 测评结果 -->
      <section class="report-section">
        <h2 class="section-title">二、测评结果</h2>

        <!-- 情绪困扰 -->
        <article class="result-article">
          <h3 class="article-title">（一）情绪困扰</h3>
          <!-- 焦虑 -->

          <div class="result-item">
            <h4 class="item-title">1.焦虑</h4>
            <RangeBar
              v-for="(result, index) in results"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <div class="item-suggestions2">
              {{ data1[0].entityExplain }}
              <div v-html="data1[0].entityAd"></div>
            </div>
          </div>
          <!-- 抑郁 -->

          <div class="result-item">
            <h4 class="item-title">2.抑郁</h4>
            <RangeBar
              v-for="(result, index) in results2"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <div class="item-suggestions2">
              {{ data1[1].entityExplain }}
              <div v-html="data1[1].entityAd"></div>
            </div>
          </div>
          <!-- 自我肯定 -->

          <div class="result-item">
            <h4 class="item-title">3.自我肯定</h4>
            <RangeBar
              v-for="(result, index) in results3"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description" v-if="data1[2].entityExplain">
              {{ data1[2].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data1[2].entityAd"></div>
          </div>
        </article>
        <!-- 心理健康素养 -->
        <article class="result-article">
          <h3 class="article-title">（二）压力</h3>
          <!-- 工作压力 -->

          <div class="result-item">
            <h4 class="item-title">1.工作压力</h4>
            <RangeBar
              v-for="(result, index) in results10"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description">
              {{ workPressureReport.express }}
            </p>

            <div class="item-suggestions">
              <div
                class="item-suggestionstitle"
                v-html="workPressureReport.advice"
              ></div>
              <div
                class="item-suggestionscontent"
                v-html="workPressureReport.context"
              ></div>
            </div>
          </div>
          <!-- 工作压力 -->

          <div class="result-item">
            <h4 class="item-title">2.生活压力</h4>
            <RangeBar
              v-for="(result, index) in results8"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description">
              {{ lifePressureReport.express }}
            </p>

            <div class="item-suggestions">
              <div
                class="item-suggestionstitle"
                v-html="lifePressureReport.advice"
              ></div>
              <div
                class="item-suggestionscontent"
                v-html="lifePressureReport.context"
              ></div>
            </div>
          </div>
        </article>

        <!-- 三。心理健康素养 -->
        <article class="result-article">
          <h3 class="article-title">（三）心理健康素养</h3>
          <!-- 焦虑 -->

          <div class="result-item">
            <h4 class="item-title">1.心理健康素养</h4>
            <RangeBar
              v-for="(result, index) in results4"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description" v-if="data2[0].entityExplain">
              {{ data2[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data2[0].entityAd"></div>
          </div>
        </article>

        <!-- 社会支持 -->
        <article class="result-article">
          <h3 class="article-title">（四）社会支持</h3>

          <div class="result-item">
            <h4 class="item-title">1.实际帮助</h4>
            <RangeBar
              v-for="(result, index) in results7"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description" v-if="data3[0].entityExplain">
              {{ data3[0].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data3[1].entityAd"></div>
          </div>
          <div class="result-item">
            <h3 class="item-title">2.情感支持</h3>
            <RangeBar
              v-for="(result, index) in results6"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description" v-if="data3[1].entityExplain">
              {{ data3[1].entityExplain }}
            </p>

            <div class="item-suggestions2" v-html="data3[0].entityAd"></div>
          </div>
        </article>

        <!-- （五）健康生活方式 -->
        <article class="result-article">
          <h3 class="article-title">（五）健康生活方式</h3>
          <!-- 自评健康 -->

          <div class="result-item">
            <h4 class="item-title">1.自评健康</h4>
            <RangeBar
              v-for="(result, index) in results7"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description">
              {{ healthByMyselfReport.express }}
            </p>
            <div class="item-suggestions">
              <div
                class="item-suggestionstitle"
                v-html="healthByMyselfReport.advice"
              ></div>
              <div
                class="item-suggestionscontent"
                v-html="healthByMyselfReport.context"
              ></div>
            </div>
          </div>
          <!-- 睡眠质量 -->

          <div class="result-item">
            <h4 class="item-title">2.睡眠质量</h4>
            <RangeBar
              v-for="(result, index) in results9"
              :key="index"
              :score="result.score"
              :maxScore="result.maxScore"
              :segments="result.segments"
              :labels="result.labels"
            />
            <p class="item-description">
              {{ sleepQualityReport.express }}
            </p>

            <div class="item-suggestions">
              <div
                class="item-suggestionstitle"
                v-html="sleepQualityReport.advice"
              ></div>
              <div
                class="item-suggestionscontent"
                v-html="sleepQualityReport.context"
              ></div>
            </div>
          </div>
        </article>
      </section>

      <button class="savabtn" @click="saveAsImage">保存报告</button>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import RangeBar from "../minsheng/component/RangeBar.vue";
import axios from "axios"; // 引入 axios
import html2canvas from "html2canvas";
import { Toast } from "vant";

export default {
  components: {
    RangeBar,
  },
  data() {
    return {
      userId: "",
      addTime: "",
      data: null,
      data1: [],
      data2: [],
      data3: [],
      sleepQualityReport: {},
      workPressureReport: {},
      lifePressureReport: {},
      healthByMyselfReport: {},
      results: [
        {
          title: "焦虑",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 15.1 },
            { color: "qiancheng", flex: 17.6 },
            { color: "cyan", flex: 33 },
            { color: "green", flex: 34.3 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results2: [
        {
          title: "抑郁",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 7.2 },
            { color: "yellow", flex: 10.5 },
            { color: "cyan", flex: 20.6 },
            { color: "green", flex: 61.7 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results3: [
        {
          title: "自我肯定",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 7.5 },
            { color: "yellow", flex: 25.5 },
            { color: "cyan", flex: 34.2 },
            { color: "green", flex: 32.8 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results4: [
        {
          title: "心理健康素养",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "yellow", flex: 60 },
            { color: "cyan", flex: 20 },
            { color: "green", flex: 20 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results5: [
        {
          title: "焦虑",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 15.1 },
            { color: "yellow", flex: 17.6 },
            { color: "cyan", flex: 33 },
            { color: "green", flex: 34.3 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results6: [
        {
          title: "焦虑",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "yellow", flex: 60 },
            { color: "cyan", flex: 20 },
            { color: "green", flex: 20 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results7: [
        {
          title: "实际帮助",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 4.5 },
            { color: "qiancheng", flex: 30.8 },
            { color: "cyan", flex: 24.6 },
            { color: "qinglv", flex: 24.3 },
            { color: "green", flex: 15.9 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results8: [
        {
          title: "生活压力",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: " green", flex: 28.8 },
            { color: " cyan", flex: 45.2 },
            { color: "yellow", flex: 21.4 },
            { color: "red", flex: 4.6 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results9: [
        {
          title: "睡眠质量",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: "red", flex: 7.1 },
            { color: "qiancheng", flex: 35.2 },
            { color: "cyan", flex: 38.4 },
            { color: "green", flex: 19.2 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
      results10: [
        {
          title: "工作压力",
          score: 20.9,
          maxScore: 100,
          segments: [
            { color: " green", flex: 23.4 },
            { color: " cyan", flex: 45 },
            { color: "yellow", flex: 27.4 },
            { color: "red", flex: 4.2 },
          ],
          labels: [
            "0",
            "10",
            "20",
            "30",
            "40",
            "50",
            "60",
            "70",
            "80",
            "90",
            "100",
          ],
          description: "焦虑是一种表现为担心、不安、紧张的情绪体验。",
        },
      ],
    };
  },
  methods: {
    saveAsImage() {
      this.$nextTick(() => {
        const container = this.$refs.container;
        if (!container) {
          console.error("未找到 container 元素！");
          return;
        }

        // 获取整个容器的宽高
        const containerHeight = container.scrollHeight;
        const containerWidth = container.scrollWidth;

        // 调用 html2canvas 截图
        html2canvas(container, {
          useCORS: true, // 支持跨域
          scale: 2, // 提高清晰度
          width: containerWidth,
          height: containerHeight,
          windowWidth: containerWidth,
          windowHeight: containerHeight,
          scrollX: 0,
          scrollY: 0,
        })
          .then((canvas) => {
            const imageURL = canvas.toDataURL("image/png");
            const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
            const isAndroid = /Android/i.test(navigator.userAgent);

            if (isWeChat && isAndroid) {
              Toast("请点击右上角保存图片，或在外部浏览器作答");
            } else {
              // 非微信安卓直接下载
              const link = document.createElement("a");
              link.href = imageURL;
              link.download = "个人报告.png";
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          })
          .catch((error) => {
            console.error("截图失败:", error);
          });
      });
    },
    fetchData() {
      // 显示加载动画
      Toast.loading({
        duration: 0, // 持续展示，需手动关闭
        message: "加载中...",
        forbidClick: true, // 禁止用户点击
      });
      const voteLogId = this.voteLogId;
      axios
        .get(`https://assess.eapchina.net/getReport877`, {
          params: { voteLogId },
        })
        .then((response) => {
          console.log("服务器返回的数据：", response.data);
          const res = response.data;

          // 数据处理
          this.data = res.data;
          this.data1 = res.data[0].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br>"),
          }));
          this.data2 = res.data[1].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br>"),
          }));
          this.data3 = res.data[2].map((item) => ({
            ...item,
            entityAd: item.entityAd.replace(/\n/g, "<br>"),
          }));

          this.sleepQualityReport = res.sleepQualityReport;
          this.workPressureReport = res.workPressureReport;
          this.lifePressureReport = res.lifePressureReport;
          this.healthByMyselfReport = res.healthByMyselfReport;

          // 图处理
          this.results[0].score = res.data[0][0].score;
          this.results2[0].score = res.data[0][1].score;
          this.results3[0].score = res.data[0][2].score;
          this.results4[0].score = res.data[1][0].score;
          this.results5[0].score = res.data[2][0].score;
          this.results6[0].score = res.data[2][1].score;
          this.results7[0].score = res.healthByMyselfReport.healthByMyselfScore;
          this.results8[0].score = res.lifePressureReport.lifePressureScore;
          this.results9[0].score = res.sleepQualityReport.sleepQualityScore;
          this.results10[0].score = res.workPressureReport.workPressureScore;

          // 用户数据处理
          this.addTime = res.addTime;
          this.userId = localStorage.getItem("userId");

          // 请求完成后关闭加载动画
          Toast.clear();
        })
        .catch((error) => {
          // 捕获请求错误
          console.error("请求发生错误：", error);

          // 关闭加载动画并显示错误提示
          Toast.clear();
          Toast.fail("加载失败，请稍后重试");
        });
    },
  },
  mounted() {},
  created() {
    console.log(this.$route.query.voteLogId);
    this.voteLogId = this.$route.query.voteLogId;
    this.fetchData(); // 在组件挂载时调用请求
  },
};
</script>

<style scoped>
/* 容器样式 */
.container {
  max-width: 3.84rem; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
  height: 100%; /* 高度自动根据内容适配 */
  display: flex;
  flex-direction: column; /* 保持子元素垂直排列 */
  box-sizing: border-box;
}

/* 顶部图片区域 */
.topimg {
  background-image: url("https://lesson.iapeap.com/images/cp/5.jpg");
  background-repeat: no-repeat; /* 避免图片重复 */
  background-size: 100% 100%;
  max-width: 100%; /* 宽度填满容器 */
  min-height: 410px;
}
.usermsg {
  position: relative;
  top: 280px;
  left: 20px;
  font-size: 14px;
  line-height: 25px;
}

/* 内容区域 */
.content {
  width: 100%;
  padding: 0 24px; /* 内容内边距 */
  background-color: #f9f9f9;
  background-image: url("https://lesson.iapeap.com/images/cp/2.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 8px; /* 圆角 */
  box-sizing: border-box;
  flex: 1; /* 占据剩余空间 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 底部图片区域 */
.footer {
  background-image: url("https://lesson.iapeap.com/images/cp/3.jpg");
  background-repeat: no-repeat; /* 避免图片重复 */
  background-size: 100% 100%;
  max-width: 100%; /* 宽度填满容器 */
  min-height: 224px;
}

/* 移动端自适应 */
@media (max-width: 3.84rem) {
  .content {
    border-radius: 0; /* 移动端去掉圆角 */
    padding: 16px; /* 减少内边距 */
  }
}
.report-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.report-date {
  font-size: 14px;
  text-align: center;
  color: #666666;
  margin-bottom: 30px;
}

/* 报告区块 */
.report-section {
  margin-bottom: 40px;
}

.section-title {
  background-color: #57c176;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.section-description {
  font-size: 16px;
  color: #444444;
  margin-bottom: 15px;
  text-indent: 20px;
}

.section-list {
  margin-left: 20px;
  color: #555555;
  font-size: 14px;
  line-height: 1.8;
}

/* 测评结果 */
.result-article {
  margin-bottom: 30px;
}

.article-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.result-item {
  margin-bottom: 20px;
  padding: 20px;
  background: #f9fdfb;
  border: 1px solid #e0f2eb;
  border-radius: 8px;
}

.item-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-description {
  font-size: 14px;
  color: #555555;
  margin-bottom: 10px;
  line-height: 25px;
}

.item-suggestions {
  list-style-type: disc;
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
  background-color: #daf6e8;
  box-sizing: border-box;
  border-radius: 20px;
}
.item-suggestions2 {
  list-style-type: disc;
  color: #666666;
  font-size: 14px;
  line-height: 1.6;
  background-color: #daf6e8;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px;
}
.item-suggestionstitle {
  padding: 8px 15px;
  border-radius: 20px 20px 0 0;
  /* font-size: 12px; */
  /* text-align: center; */

  background-color: #adddc3;
}
.item-suggestionscontent {
  padding: 10px 15px;
}
.range-bar {
  margin: 15px 0;
  position: relative;
  width: 100%;
}

.range-bar-labels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.range-bar-track {
  position: relative;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
}

.range-bar-segment {
  flex: 1;
  height: 100%;
}

.segment-low {
  background-color: #f85a3e;
}

.segment-medium {
  background-color: #ffa41b;
}

.segment-high {
  background-color: #57d9a3;
}

.range-bar-indicator {
  position: absolute;
  top: -6px;
  transform: translateX(-50%);
  height: 24px;
  width: 24px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.savabtn {
  padding: 10px 20px;
  background-color: skyblue;
  border-radius: 20px;
  border: 0;
  color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
